<div class="container" fxLayout="row" fxLayoutAlign="left">
  <div fxFlex="50%" fxFlex.lt-md="75%" fxFlex.lt-sm="100%">
    <ng-content></ng-content>
  </div>
</div>
<p i18n="@@resubscribe">
  If you wish to continue receiving marketing communications, please
  resubscribe.
</p>
<div class="container" fxLayout="row" fxLayoutAlign="left">
  <button
    class="resub-btn"
    mat-stroked-button
    type="button"
    (click)="onResubscribe()"
    i18n="@@resubscribe_btn">
    Resubscribe
  </button>
</div>
<div class="empty-space"></div>
