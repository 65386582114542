import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MpdRoutes } from '@libs/cross-plat';
import { checkRegionGuard } from './guards/check-region.guard';
import { headerFooterResolver } from './guards/header-footer.resolver';
import { LayoutComponent } from './pages/layout/layout.component';
import { ManagePreferencesComponent } from './pages/manage-preferences/manage-preferences.component';
import { StayConnectedComponent } from './pages/stay-connected/stay-connected.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { MpdUnsubscribeSurveyComponent } from './pages/unsubscribe-survey/mpd-unsubscribe-survey.component';
import { MpdUnsubscribeComponent } from './pages/unsubscribe/mpd-unsubscribe.component';

const routing: Routes = [
  {
    path: '',
    redirectTo: '/en',
    pathMatch: 'full',
  },
  {
    path: ':region',
    component: LayoutComponent,
    canActivate: [checkRegionGuard],
    resolve: { data: headerFooterResolver },
    children: [
      {
        path: MpdRoutes.StayConnected,
        component: StayConnectedComponent,
        title: 'Stay Connected',
      },
      {
        path: MpdRoutes.ThankYou,
        component: ThankYouComponent,
        title: 'Registration Confirmation',
      },
      {
        path: MpdRoutes.ManagePreferences,
        component: ManagePreferencesComponent,
        title: 'Manage My Preferences',
      },
      {
        path: MpdRoutes.Unsubscribed,
        component: MpdUnsubscribeComponent,
        title: 'Unsubscribe',
      },
      {
        path: MpdRoutes.UnsubscribeSurvey,
        component: MpdUnsubscribeSurveyComponent,
        title: 'Unsubscribe Survey',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routing, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
